/* *
 *
 *  (c) 2010-2024 Torstein Honsi
 *
 *  License: www.highcharts.com/license
 *
 *  !!!!!!! SOURCE GETS TRANSPILED BY TYPESCRIPT. EDIT TS FILE ONLY. !!!!!!!
 *
 * */
'use strict';

/* *
 *
 *  Constant
 *
 * */
/**
 *
 * The scrollbar is a means of panning over the X axis of a stock chart.
 * Scrollbars can also be applied to other types of axes.
 *
 * Another approach to scrollable charts is the [chart.scrollablePlotArea](
 * https://api.highcharts.com/highcharts/chart.scrollablePlotArea) option that
 * is especially suitable for simpler cartesian charts on mobile.
 *
 * In styled mode, all the presentational options for the
 * scrollbar are replaced by the classes `.highcharts-scrollbar-thumb`,
 * `.highcharts-scrollbar-arrow`, `.highcharts-scrollbar-button`,
 * `.highcharts-scrollbar-rifles` and `.highcharts-scrollbar-track`.
 *
 * @sample stock/yaxis/inverted-bar-scrollbar/
 *         A scrollbar on a simple bar chart
 *
 * @product highstock gantt
 * @optionparent scrollbar
 *
 * @private
 */
const ScrollbarDefaults = {
  /**
   * The height of the scrollbar. If `buttonsEnabled` is true , the height
   * also applies to the width of the scroll arrows so that they are always
   * squares.
   *
   * @sample stock/scrollbar/style/
   *         Non-default height
   *
   * @type    {number}
   */
  height: 10,
  /**
   * The border rounding radius of the bar.
   *
   * @sample stock/scrollbar/style/
   *         Scrollbar styling
   */
  barBorderRadius: 5,
  /**
   * The corner radius of the scrollbar buttons.
   *
   * @sample stock/scrollbar/style/
   *         Scrollbar styling
   */
  buttonBorderRadius: 0,
  /**
   * Enable or disable the buttons at the end of the scrollbar.
   *
   * @since 11.0.0
   */
  buttonsEnabled: false,
  /**
   * Enable or disable the scrollbar.
   *
   * @sample stock/scrollbar/enabled/
   *         Disable the scrollbar, only use navigator
   *
   * @type      {boolean}
   * @default   true
   * @apioption scrollbar.enabled
   */
  /**
   * Whether to redraw the main chart as the scrollbar or the navigator
   * zoomed window is moved. Defaults to `true` for modern browsers and
   * `false` for legacy IE browsers as well as mobile devices.
   *
   * @sample stock/scrollbar/liveredraw
   *         Setting live redraw to false
   *
   * @type  {boolean}
   * @since 1.3
   */
  liveRedraw: void 0,
  /**
   * The margin between the scrollbar and its axis when the scrollbar is
   * applied directly to an axis, or the navigator in case that is enabled.
   * Defaults to 10 for axis, 0 for navigator.
   *
   * @type {number|undefined}
   */
  margin: void 0,
  /**
   * The minimum width of the scrollbar.
   *
   * @since 1.2.5
   */
  minWidth: 6,
  /** @ignore-option */
  opposite: true,
  /**
   * Whether to show or hide the scrollbar when the scrolled content is
   * zoomed out to it full extent.
   *
   * @type      {boolean}
   * @default   true
   * @apioption scrollbar.showFull
   */
  step: 0.2,
  /**
   * The z index of the scrollbar group.
   */
  zIndex: 3,
  /**
   * The background color of the scrollbar itself.
   *
   * @sample stock/scrollbar/style/
   *         Scrollbar styling
   *
   * @type {Highcharts.ColorString|Highcharts.GradientColorObject|Highcharts.PatternObject}
   */
  barBackgroundColor: "#cccccc" /* Palette.neutralColor20 */,

  /**
   * The width of the bar's border.
   *
   * @sample stock/scrollbar/style/
   *         Scrollbar styling
   */
  barBorderWidth: 0,
  /**
   * The color of the scrollbar's border.
   *
   * @type {Highcharts.ColorString|Highcharts.GradientColorObject|Highcharts.PatternObject}
   */
  barBorderColor: "#cccccc" /* Palette.neutralColor20 */,

  /**
   * The color of the small arrow inside the scrollbar buttons.
   *
   * @sample stock/scrollbar/style/
   *         Scrollbar styling
   *
   * @type {Highcharts.ColorString|Highcharts.GradientColorObject|Highcharts.PatternObject}
   */
  buttonArrowColor: "#333333" /* Palette.neutralColor80 */,

  /**
   * The color of scrollbar buttons.
   *
   * @sample stock/scrollbar/style/
   *         Scrollbar styling
   *
   * @type {Highcharts.ColorString|Highcharts.GradientColorObject|Highcharts.PatternObject}
   */
  buttonBackgroundColor: "#e6e6e6" /* Palette.neutralColor10 */,

  /**
   * The color of the border of the scrollbar buttons.
   *
   * @sample stock/scrollbar/style/
   *         Scrollbar styling
   *
   * @type {Highcharts.ColorString|Highcharts.GradientColorObject|Highcharts.PatternObject}
   */
  buttonBorderColor: "#cccccc" /* Palette.neutralColor20 */,

  /**
   * The border width of the scrollbar buttons.
   *
   * @sample stock/scrollbar/style/
   *         Scrollbar styling
   */
  buttonBorderWidth: 1,
  /**
   * The color of the small rifles in the middle of the scrollbar.
   *
   * @type {Highcharts.ColorString|Highcharts.GradientColorObject|Highcharts.PatternObject}
   */
  rifleColor: 'none',
  /**
   * The color of the track background.
   *
   * @sample stock/scrollbar/style/
   *         Scrollbar styling
   *
   * @type {Highcharts.ColorString|Highcharts.GradientColorObject|Highcharts.PatternObject}
   */
  trackBackgroundColor: 'rgba(255, 255, 255, 0.001)',
  // #18922
  /**
   * The color of the border of the scrollbar track.
   *
   * @sample stock/scrollbar/style/
   *         Scrollbar styling
   *
   * @type {Highcharts.ColorString|Highcharts.GradientColorObject|Highcharts.PatternObject}
   */
  trackBorderColor: "#cccccc" /* Palette.neutralColor20 */,

  /**
   * The corner radius of the border of the scrollbar track.
   *
   * @sample stock/scrollbar/style/
   *         Scrollbar styling
   */
  trackBorderRadius: 5,
  /**
   * The width of the border of the scrollbar track.
   *
   * @sample stock/scrollbar/style/
   *         Scrollbar styling
   */
  trackBorderWidth: 1
};
/* *
 *
 *  Default Export
 *
 * */
export default ScrollbarDefaults;