/* *
 *
 *  (c) 2010-2024 Torstein Honsi
 *
 *  License: www.highcharts.com/license
 *
 *  !!!!!!! SOURCE GETS TRANSPILED BY TYPESCRIPT. EDIT TS FILE ONLY. !!!!!!!
 *
 * */
'use strict';

import H from '../../Core/Globals.js';
const {
  isTouchDevice
} = H;
/* *
 *
 *  API Options
 *
 * */
// Add the export related options
/**
 * Options for the exporting module. For an overview on the matter, see
 * [the docs](https://www.highcharts.com/docs/export-module/export-module-overview).
 *
 * @requires     modules/exporting
 * @optionparent exporting
 */
const exporting = {
  /**
   * Experimental setting to allow HTML inside the chart (added through
   * the `useHTML` options), directly in the exported image. This allows
   * you to preserve complicated HTML structures like tables or bi-directional
   * text in exported charts.
   *
   * Disclaimer: The HTML is rendered in a `foreignObject` tag in the
   * generated SVG. The official export server is based on PhantomJS,
   * which supports this, but other SVG clients, like Batik, does not
   * support it. This also applies to downloaded SVG that you want to
   * open in a desktop client.
   *
   * @type      {boolean}
   * @default   false
   * @since     4.1.8
   * @apioption exporting.allowHTML
   */
  /**
   * Allows the end user to sort the data table by clicking on column headers.
   *
   * @since 10.3.3
   * @apioption exporting.allowTableSorting
   */
  allowTableSorting: true,
  /**
   * Additional chart options to be merged into the chart before exporting to
   * an image format. This does not apply to printing the chart via the export
   * menu.
   *
   * For example, a common use case is to add data labels to improve
   * readability of the exported chart, or to add a printer-friendly color
   * scheme to exported PDFs.
   *
   * @sample {highcharts} highcharts/exporting/chartoptions-data-labels/
   *         Added data labels
   * @sample {highstock} highcharts/exporting/chartoptions-data-labels/
   *         Added data labels
   *
   * @type      {Highcharts.Options}
   * @apioption exporting.chartOptions
   */
  /**
   * Whether to enable the exporting module. Disabling the module will
   * hide the context button, but API methods will still be available.
   *
   * @sample {highcharts} highcharts/exporting/enabled-false/
   *         Exporting module is loaded but disabled
   * @sample {highstock} highcharts/exporting/enabled-false/
   *         Exporting module is loaded but disabled
   *
   * @type      {boolean}
   * @default   true
   * @since     2.0
   * @apioption exporting.enabled
   */
  /**
   * Function to call if the offline-exporting module fails to export
   * a chart on the client side, and [fallbackToExportServer](
   * #exporting.fallbackToExportServer) is disabled. If left undefined, an
   * exception is thrown instead. Receives two parameters, the exporting
   * options, and the error from the module.
   *
   * @see [fallbackToExportServer](#exporting.fallbackToExportServer)
   *
   * @type      {Highcharts.ExportingErrorCallbackFunction}
   * @since     5.0.0
   * @requires  modules/exporting
   * @requires  modules/offline-exporting
   * @apioption exporting.error
   */
  /**
   * Whether or not to fall back to the export server if the offline-exporting
   * module is unable to export the chart on the client side. This happens for
   * certain browsers, and certain features (e.g.
   * [allowHTML](#exporting.allowHTML)), depending on the image type exporting
   * to. For very complex charts, it is possible that export can fail in
   * browsers that don't support Blob objects, due to data URL length limits.
   * It is recommended to define the [exporting.error](#exporting.error)
   * handler if disabling fallback, in order to notify users in case export
   * fails.
   *
   * @type      {boolean}
   * @default   true
   * @since     4.1.8
   * @requires  modules/exporting
   * @requires  modules/offline-exporting
   * @apioption exporting.fallbackToExportServer
   */
  /**
   * The filename, without extension, to use for the exported chart.
   *
   * @sample {highcharts} highcharts/exporting/filename/
   *         Custom file name
   * @sample {highstock} highcharts/exporting/filename/
   *         Custom file name
   *
   * @type      {string}
   * @default   chart
   * @since     2.0
   * @apioption exporting.filename
   */
  /**
   * Highcharts v11.2.0 and older. An object containing additional key value
   * data for the POST form that sends the SVG to the export server. For
   * example, a `target` can be set to make sure the generated image is
   * received in another frame, or a custom `enctype` or `encoding` can be
   * set.
   *
   * With Highcharts v11.3.0, the `fetch` API replaced the old HTML form. To
   * modify the request, now use [fetchOptions](#exporting.fetchOptions)
   * instead.
   *
   * @deprecated
   * @type      {Highcharts.HTMLAttributes}
   * @since     3.0.8
   * @apioption exporting.formAttributes
   */
  /**
   * Options for the fetch request used when sending the SVG to the export
   * server.
   *
   * See [MDN](https://developer.mozilla.org/en-US/docs/Web/API/fetch)
   * for more information
   *
   * @type {Object}
   * @since 11.3.0
   * @apioption exporting.fetchOptions
   */
  /**
   * Path where Highcharts will look for export module dependencies to
   * load on demand if they don't already exist on `window`. Should currently
   * point to location of [CanVG](https://github.com/canvg/canvg) library,
   * [jsPDF](https://github.com/parallax/jsPDF) and
   * [svg2pdf.js](https://github.com/yWorks/svg2pdf.js), required for client
   * side export in certain browsers.
   *
   * @type      {string}
   * @default   https://code.highcharts.com/{version}/lib
   * @since     5.0.0
   * @apioption exporting.libURL
   */
  /**
   * Analogous to [sourceWidth](#exporting.sourceWidth).
   *
   * @type      {number}
   * @since     3.0
   * @apioption exporting.sourceHeight
   */
  /**
   * The width of the original chart when exported, unless an explicit
   * [chart.width](#chart.width) is set, or a pixel width is set on the
   * container. The width exported raster image is then multiplied by
   * [scale](#exporting.scale).
   *
   * @sample {highcharts} highcharts/exporting/sourcewidth/
   *         Source size demo
   * @sample {highstock} highcharts/exporting/sourcewidth/
   *         Source size demo
   * @sample {highmaps} maps/exporting/sourcewidth/
   *         Source size demo
   *
   * @type      {number}
   * @since     3.0
   * @apioption exporting.sourceWidth
   */
  /**
   * The pixel width of charts exported to PNG or JPG. As of Highcharts
   * 3.0, the default pixel width is a function of the [chart.width](
   * #chart.width) or [exporting.sourceWidth](#exporting.sourceWidth) and the
   * [exporting.scale](#exporting.scale).
   *
   * @sample {highcharts} highcharts/exporting/width/
   *         Export to 200px wide images
   * @sample {highstock} highcharts/exporting/width/
   *         Export to 200px wide images
   *
   * @type      {number}
   * @since     2.0
   * @apioption exporting.width
   */
  /**
   * Default MIME type for exporting if `chart.exportChart()` is called
   * without specifying a `type` option. Possible values are `image/png`,
   *  `image/jpeg`, `application/pdf` and `image/svg+xml`.
   *
   * @type  {Highcharts.ExportingMimeTypeValue}
   * @since 2.0
   */
  type: 'image/png',
  /**
   * The URL for the server module converting the SVG string to an image
   * format. By default this points to Highchart's free web service.
   *
   * @since 2.0
   */
  url: 'https://export.highcharts.com/',
  /**
   * Settings for a custom font for the exported PDF, when using the
   * `offline-exporting` module. This is used for languages containing
   * non-ASCII characters, like Chinese, Russian, Japanese etc.
   *
   * As described in the [jsPDF
   * docs](https://github.com/parallax/jsPDF#use-of-unicode-characters--utf-8),
   * the 14 standard fonts in PDF are limited to the ASCII-codepage.
   * Therefore, in order to support other text in the exported PDF, one or
   * more TTF font files have to be passed on to the exporting module.
   *
   * See more in [the
   * docs](https://www.highcharts.com/docs/export-module/client-side-export).
   *
   * @sample {highcharts} highcharts/exporting/offline-download-pdffont/
   *         Download PDF in a language containing non-Latin characters.
   *
   * @since 10.0.0
   * @requires modules/offline-exporting
   */
  pdfFont: {
    /**
     * The TTF font file for normal `font-style`. If font variations like
     * `bold` or `italic` are not defined, the `normal` font will be used
     * for those too.
     *
     * @type string|undefined
     */
    normal: void 0,
    /**
     * The TTF font file for bold text.
     *
     * @type string|undefined
     */
    bold: void 0,
    /**
     * The TTF font file for bold and italic text.
     *
     * @type string|undefined
     */
    bolditalic: void 0,
    /**
     * The TTF font file for italic text.
     *
     * @type string|undefined
     */
    italic: void 0
  },
  /**
   * When printing the chart from the menu item in the burger menu, if
   * the on-screen chart exceeds this width, it is resized. After printing
   * or cancelled, it is restored. The default width makes the chart
   * fit into typical paper format. Note that this does not affect the
   * chart when printing the web page as a whole.
   *
   * @since 4.2.5
   */
  printMaxWidth: 780,
  /**
   * Defines the scale or zoom factor for the exported image compared
   * to the on-screen display. While for instance a 600px wide chart
   * may look good on a website, it will look bad in print. The default
   * scale of 2 makes this chart export to a 1200px PNG or JPG.
   *
   * @see [chart.width](#chart.width)
   * @see [exporting.sourceWidth](#exporting.sourceWidth)
   *
   * @sample {highcharts} highcharts/exporting/scale/
   *         Scale demonstrated
   * @sample {highstock} highcharts/exporting/scale/
   *         Scale demonstrated
   * @sample {highmaps} maps/exporting/scale/
   *         Scale demonstrated
   *
   * @since 3.0
   */
  scale: 2,
  /**
   * Options for the export related buttons, print and export. In addition
   * to the default buttons listed here, custom buttons can be added.
   * See [navigation.buttonOptions](#navigation.buttonOptions) for general
   * options.
   *
   * @type     {Highcharts.Dictionary<*>}
   * @requires modules/exporting
   */
  buttons: {
    /**
     * Options for the export button.
     *
     * In styled mode, export button styles can be applied with the
     * `.highcharts-contextbutton` class.
     *
     * @declare  Highcharts.ExportingButtonsOptionsObject
     * @extends  navigation.buttonOptions
     * @requires modules/exporting
     */
    contextButton: {
      /**
       * A click handler callback to use on the button directly instead of
       * the popup menu.
       *
       * @sample highcharts/exporting/buttons-contextbutton-onclick/
       *         Skip the menu and export the chart directly
       *
       * @type      {Function}
       * @since     2.0
       * @apioption exporting.buttons.contextButton.onclick
       */
      /**
       * See [navigation.buttonOptions.symbolFill](
       * #navigation.buttonOptions.symbolFill).
       *
       * @type      {Highcharts.ColorString}
       * @default   #666666
       * @since     2.0
       * @apioption exporting.buttons.contextButton.symbolFill
       */
      /**
       * The horizontal position of the button relative to the `align`
       * option.
       *
       * @type      {number}
       * @default   -10
       * @since     2.0
       * @apioption exporting.buttons.contextButton.x
       */
      /**
       * The class name of the context button.
       */
      className: 'highcharts-contextbutton',
      /**
       * The class name of the menu appearing from the button.
       */
      menuClassName: 'highcharts-contextmenu',
      /**
       * The symbol for the button. Points to a definition function in
       * the `Highcharts.Renderer.symbols` collection. The default
       * `menu` function is part of the exporting module. Possible
       * values are "circle", "square", "diamond", "triangle",
       * "triangle-down", "menu", "menuball" or custom shape.
       *
       * @sample highcharts/exporting/buttons-contextbutton-symbol/
       *         Use a circle for symbol
       * @sample highcharts/exporting/buttons-contextbutton-symbol-custom/
       *         Custom shape as symbol
       *
       * @type  {Highcharts.SymbolKeyValue|"menu"|"menuball"|string}
       * @since 2.0
       */
      symbol: 'menu',
      /**
       * The key to a [lang](#lang) option setting that is used for the
       * button's title tooltip. When the key is `contextButtonTitle`, it
       * refers to [lang.contextButtonTitle](#lang.contextButtonTitle)
       * that defaults to "Chart context menu".
       *
       * @since 6.1.4
       */
      titleKey: 'contextButtonTitle',
      /**
       * This option is deprecated, use
       * [titleKey](#exporting.buttons.contextButton.titleKey) instead.
       *
       * @deprecated
       * @type      {string}
       * @apioption exporting.buttons.contextButton._titleKey
       */
      /**
       * A collection of strings pointing to config options for the menu
       * items. The config options are defined in the
       * `menuItemDefinitions` option.
       *
       * By default, there is the "View in full screen" and "Print" menu
       * items, plus one menu item for each of the available export types.
       *
       * @sample {highcharts} highcharts/exporting/menuitemdefinitions/
       *         Menu item definitions
       * @sample {highstock} highcharts/exporting/menuitemdefinitions/
       *         Menu item definitions
       * @sample {highmaps} highcharts/exporting/menuitemdefinitions/
       *         Menu item definitions
       *
       * @type    {Array<string>}
       * @default ["viewFullscreen", "printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"]
       * @since   2.0
       */
      menuItems: ['viewFullscreen', 'printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG']
    }
  },
  /**
   * An object consisting of definitions for the menu items in the context
   * menu. Each key value pair has a `key` that is referenced in the
   * [menuItems](#exporting.buttons.contextButton.menuItems) setting,
   * and a `value`, which is an object with the following properties:
   *
   * - **onclick:** The click handler for the menu item
   *
   * - **text:** The text for the menu item
   *
   * - **textKey:** If internationalization is required, the key to a language
   *   string
   *
   * Custom text for the "exitFullScreen" can be set only in lang options
   * (it is not a separate button).
   *
   * @sample {highcharts} highcharts/exporting/menuitemdefinitions/
   *         Menu item definitions
   * @sample {highstock} highcharts/exporting/menuitemdefinitions/
   *         Menu item definitions
   * @sample {highmaps} highcharts/exporting/menuitemdefinitions/
   *         Menu item definitions
   *
   *
   * @type    {Highcharts.Dictionary<Highcharts.ExportingMenuObject>}
   * @default {"viewFullscreen": {}, "printChart": {}, "separator": {}, "downloadPNG": {}, "downloadJPEG": {}, "downloadPDF": {}, "downloadSVG": {}}
   * @since   5.0.13
   */
  menuItemDefinitions: {
    /**
     * @ignore
     */
    viewFullscreen: {
      textKey: 'viewFullscreen',
      onclick: function () {
        if (this.fullscreen) {
          this.fullscreen.toggle();
        }
      }
    },
    /**
     * @ignore
     */
    printChart: {
      textKey: 'printChart',
      onclick: function () {
        this.print();
      }
    },
    /**
     * @ignore
     */
    separator: {
      separator: true
    },
    /**
     * @ignore
     */
    downloadPNG: {
      textKey: 'downloadPNG',
      onclick: function () {
        this.exportChart();
      }
    },
    /**
     * @ignore
     */
    downloadJPEG: {
      textKey: 'downloadJPEG',
      onclick: function () {
        this.exportChart({
          type: 'image/jpeg'
        });
      }
    },
    /**
     * @ignore
     */
    downloadPDF: {
      textKey: 'downloadPDF',
      onclick: function () {
        this.exportChart({
          type: 'application/pdf'
        });
      }
    },
    /**
     * @ignore
     */
    downloadSVG: {
      textKey: 'downloadSVG',
      onclick: function () {
        this.exportChart({
          type: 'image/svg+xml'
        });
      }
    }
  }
};
// Add language
/**
 * @optionparent lang
 */
const lang = {
  /**
   * Exporting module only. The text for the menu item to view the chart
   * in full screen.
   *
   * @since 8.0.1
   */
  viewFullscreen: 'View in full screen',
  /**
   * Exporting module only. The text for the menu item to exit the chart
   * from full screen.
   *
   * @since 8.0.1
   */
  exitFullscreen: 'Exit from full screen',
  /**
   * Exporting module only. The text for the menu item to print the chart.
   *
   * @since    3.0.1
   * @requires modules/exporting
   */
  printChart: 'Print chart',
  /**
   * Exporting module only. The text for the PNG download menu item.
   *
   * @since    2.0
   * @requires modules/exporting
   */
  downloadPNG: 'Download PNG image',
  /**
   * Exporting module only. The text for the JPEG download menu item.
   *
   * @since    2.0
   * @requires modules/exporting
   */
  downloadJPEG: 'Download JPEG image',
  /**
   * Exporting module only. The text for the PDF download menu item.
   *
   * @since    2.0
   * @requires modules/exporting
   */
  downloadPDF: 'Download PDF document',
  /**
   * Exporting module only. The text for the SVG download menu item.
   *
   * @since    2.0
   * @requires modules/exporting
   */
  downloadSVG: 'Download SVG vector image',
  /**
   * Exporting module menu. The tooltip title for the context menu holding
   * print and export menu items.
   *
   * @since    3.0
   * @requires modules/exporting
   */
  contextButtonTitle: 'Chart context menu'
};
/**
 * A collection of options for buttons and menus appearing in the exporting
 * module or in Stock Tools.
 *
 * @requires     modules/exporting
 * @optionparent navigation
 */
const navigation = {
  /**
   * A collection of options for buttons appearing in the exporting
   * module.
   *
   * In styled mode, the buttons are styled with the
   * `.highcharts-contextbutton` and `.highcharts-button-symbol` classes.
   *
   * @requires modules/exporting
   */
  buttonOptions: {
    /**
     * Whether to enable buttons.
     *
     * @sample highcharts/navigation/buttonoptions-enabled/
     *         Exporting module loaded but buttons disabled
     *
     * @type      {boolean}
     * @default   true
     * @since     2.0
     * @apioption navigation.buttonOptions.enabled
     */
    /**
     * The pixel size of the symbol on the button.
     *
     * @sample highcharts/navigation/buttonoptions-height/
     *         Bigger buttons
     *
     * @since 2.0
     */
    symbolSize: 14,
    /**
     * The x position of the center of the symbol inside the button.
     *
     * @sample highcharts/navigation/buttonoptions-height/
     *         Bigger buttons
     *
     * @since 2.0
     */
    symbolX: 14.5,
    /**
     * The y position of the center of the symbol inside the button.
     *
     * @sample highcharts/navigation/buttonoptions-height/
     *         Bigger buttons
     *
     * @since 2.0
     */
    symbolY: 13.5,
    /**
     * Alignment for the buttons.
     *
     * @sample highcharts/navigation/buttonoptions-align/
     *         Center aligned
     *
     * @type  {Highcharts.AlignValue}
     * @since 2.0
     */
    align: 'right',
    /**
     * The pixel spacing between buttons.
     *
     * @since 2.0
     */
    buttonSpacing: 3,
    /**
     * Pixel height of the buttons.
     *
     * @sample highcharts/navigation/buttonoptions-height/
     *         Bigger buttons
     *
     * @since 2.0
     */
    height: 28,
    /**
     * A text string to add to the individual button.
     *
     * @sample highcharts/exporting/buttons-text/
     *         Full text button
     * @sample highcharts/exporting/buttons-text-usehtml/
     *         Icon using CSS font in text
     * @sample highcharts/exporting/buttons-text-symbol/
     *         Combined symbol and text
     *
     * @type      {string}
     * @default   null
     * @since     3.0
     * @apioption navigation.buttonOptions.text
     */
    /**
     * Whether to use HTML for rendering the button. HTML allows for things
     * like inline CSS or image-based icons.
     *
     * @sample highcharts/exporting/buttons-text-usehtml/
     *         Icon using CSS font in text
     *
     * @type      boolean
     * @default   false
     * @since 10.3.0
     * @apioption navigation.buttonOptions.useHTML
     */
    /**
     * The vertical offset of the button's position relative to its
     * `verticalAlign`.
     *
     * @sample highcharts/navigation/buttonoptions-verticalalign/
     *         Buttons at lower right
     *
     * @type      {number}
     * @default   0
     * @since     2.0
     * @apioption navigation.buttonOptions.y
     */
    /**
     * The vertical alignment of the buttons. Can be one of `"top"`,
     * `"middle"` or `"bottom"`.
     *
     * @sample highcharts/navigation/buttonoptions-verticalalign/
     *         Buttons at lower right
     *
     * @type  {Highcharts.VerticalAlignValue}
     * @since 2.0
     */
    verticalAlign: 'top',
    /**
     * The pixel width of the button.
     *
     * @sample highcharts/navigation/buttonoptions-height/
     *         Bigger buttons
     *
     * @since 2.0
     */
    width: 28,
    /**
     * Fill color for the symbol within the button.
     *
     * @sample highcharts/navigation/buttonoptions-symbolfill/
     *         Blue symbol stroke for one of the buttons
     *
     * @type  {Highcharts.ColorString|Highcharts.GradientColorObject|Highcharts.PatternObject}
     * @since 2.0
     */
    symbolFill: "#666666" /* Palette.neutralColor60 */,

    /**
     * The color of the symbol's stroke or line.
     *
     * @sample highcharts/navigation/buttonoptions-symbolstroke/
     *         Blue symbol stroke
     *
     * @type  {Highcharts.ColorString}
     * @since 2.0
     */
    symbolStroke: "#666666" /* Palette.neutralColor60 */,

    /**
     * The pixel stroke width of the symbol on the button.
     *
     * @sample highcharts/navigation/buttonoptions-height/
     *         Bigger buttons
     *
     * @since 2.0
     */
    symbolStrokeWidth: 3,
    /**
     * A configuration object for the button theme. The object accepts
     * SVG properties like `stroke-width`, `stroke` and `fill`.
     * Tri-state button styles are supported by the `states.hover` and
     * `states.select` objects.
     *
     * @sample highcharts/navigation/buttonoptions-theme/
     *         Theming the buttons
     *
     * @requires modules/exporting
     *
     * @since 3.0
     */
    theme: {
      /**
       * The default fill exists only to capture hover events.
       *
       * @type      {Highcharts.ColorString|Highcharts.GradientColorObject|Highcharts.PatternObject}
       */
      fill: "#ffffff" /* Palette.backgroundColor */,

      /**
       * Padding for the button.
       */
      padding: 5,
      /**
       * Default stroke for the buttons.
       *
       * @type      {Highcharts.ColorString}
       */
      stroke: 'none',
      /**
       * Default stroke linecap for the buttons.
       */
      'stroke-linecap': 'round'
    }
  },
  /**
   * CSS styles for the popup menu appearing by default when the export
   * icon is clicked. This menu is rendered in HTML.
   *
   * @see In styled mode, the menu is styled with the `.highcharts-menu`
   *      class.
   *
   * @sample highcharts/navigation/menustyle/
   *         Light gray menu background
   *
   * @type    {Highcharts.CSSObject}
   * @default {"background": "#ffffff", "borderRadius": "3px", "padding": "0.5em"}
   * @since   2.0
   */
  menuStyle: {
    /** @ignore-option */
    border: 'none',
    /** @ignore-option */
    borderRadius: '3px',
    /** @ignore-option */
    background: "#ffffff" /* Palette.backgroundColor */,

    /** @ignore-option */
    padding: '0.5em'
  },
  /**
   * CSS styles for the individual items within the popup menu appearing
   * by default when the export icon is clicked. The menu items are
   * rendered in HTML. Font size defaults to `11px` on desktop and `14px`
   * on touch devices.
   *
   * @see In styled mode, the menu items are styled with the
   *      `.highcharts-menu-item` class.
   *
   * @sample {highcharts} highcharts/navigation/menuitemstyle/
   *         Add a grey stripe to the left
   *
   * @type    {Highcharts.CSSObject}
   * @default {"padding": "0.5em", "color": "#333333", "background": "none", "borderRadius": "3px", "fontSize": "0.8em", "transition": "background 250ms, color 250ms"}
   * @since   2.0
   */
  menuItemStyle: {
    /** @ignore-option */
    background: 'none',
    /** @ignore-option */
    borderRadius: '3px',
    /** @ignore-option */
    color: "#333333" /* Palette.neutralColor80 */,

    /** @ignore-option */
    padding: '0.5em',
    /** @ignore-option */
    fontSize: isTouchDevice ? '0.9em' : '0.8em',
    /** @ignore-option */
    transition: 'background 250ms, color 250ms'
  },
  /**
   * CSS styles for the hover state of the individual items within the
   * popup menu appearing by default when the export icon is clicked. The
   * menu items are rendered in HTML.
   *
   * @see In styled mode, the menu items are styled with the
   *      `.highcharts-menu-item` class.
   *
   * @sample highcharts/navigation/menuitemhoverstyle/
   *         Bold text on hover
   *
   * @type    {Highcharts.CSSObject}
   * @default {"background": "#f2f2f2" }
   * @since   2.0
   */
  menuItemHoverStyle: {
    /** @ignore-option */
    background: "#f2f2f2" /* Palette.neutralColor5 */
  }
};
/* *
 *
 *  Default Export
 *
 * */
const ExportingDefaults = {
  exporting,
  lang,
  navigation
};
export default ExportingDefaults;