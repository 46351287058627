/* *
 *
 *  (c) 2010-2024 Torstein Honsi
 *
 *  License: www.highcharts.com/license
 *
 *  !!!!!!! SOURCE GETS TRANSPILED BY TYPESCRIPT. EDIT TS FILE ONLY. !!!!!!!
 *
 * */
'use strict';

import Color from '../../Core/Color/Color.js';
const {
  parse: color
} = Color;
import SeriesRegistry from '../../Core/Series/SeriesRegistry.js';
const {
  seriesTypes
} = SeriesRegistry;
/* *
 *
 *  Constants
 *
 * */
/**
 * The navigator is a small series below the main series, displaying
 * a view of the entire data set. It provides tools to zoom in and
 * out on parts of the data as well as panning across the dataset.
 *
 * @product      highstock gantt
 * @optionparent navigator
 */
const NavigatorDefaults = {
  /**
   * Whether the navigator and scrollbar should adapt to updated data
   * in the base X axis. When loading data async, as in the demo below,
   * this should be `false`. Otherwise new data will trigger navigator
   * redraw, which will cause unwanted looping. In the demo below, the
   * data in the navigator is set only once. On navigating, only the main
   * chart content is updated.
   *
   * @sample {highstock} stock/demo/lazy-loading/
   *         Set to false with async data loading
   *
   * @type      {boolean}
   * @default   true
   * @apioption navigator.adaptToUpdatedData
   */
  /**
   * An integer identifying the index to use for the base series, or a
   * string representing the id of the series.
   *
   * **Note**: As of Highcharts 5.0, this is now a deprecated option.
   * Prefer [series.showInNavigator](#plotOptions.series.showInNavigator).
   *
   * @see [series.showInNavigator](#plotOptions.series.showInNavigator)
   *
   * @deprecated
   * @type      {number|string}
   * @default   0
   * @apioption navigator.baseSeries
   */
  /**
   * Enable or disable the navigator.
   *
   * @sample {highstock} stock/navigator/enabled/
   *         Disable the navigator
   *
   * @type      {boolean}
   * @default   true
   * @apioption navigator.enabled
   */
  /**
   * When the chart is inverted, whether to draw the navigator on the
   * opposite side.
   *
   * @type      {boolean}
   * @default   false
   * @since     5.0.8
   * @apioption navigator.opposite
   */
  /**
   * The height of the navigator.
   *
   * @sample {highstock} stock/navigator/height/
   *         A higher navigator
   */
  height: 40,
  /**
   * The distance from the nearest element, the X axis or X axis labels.
   *
   * @sample {highstock} stock/navigator/margin/
   *         A margin of 2 draws the navigator closer to the X axis labels
   */
  margin: 25,
  /**
   * Whether the mask should be inside the range marking the zoomed
   * range, or outside. In Highcharts Stock 1.x it was always `false`.
   *
   * @sample {highstock} stock/demo/maskinside-false/
   *         False, mask outside
   *
   * @since   2.0
   */
  maskInside: true,
  /**
   * Options for the handles for dragging the zoomed area.
   *
   * @sample {highstock} stock/navigator/handles/
   *         Colored handles
   */
  handles: {
    /**
     * Width for handles.
     *
     * @sample {highstock} stock/navigator/styled-handles/
     *         Styled handles
     *
     * @since   6.0.0
     */
    width: 7,
    /**
     * Border radius of the handles.
     *
     * @sample {highstock} stock/navigator/handles-border-radius/
     *      Border radius on the navigator handles.
     *
     * @since 11.4.2
     */
    borderRadius: 0,
    /**
     * Height for handles.
     *
     * @sample {highstock} stock/navigator/styled-handles/
     *         Styled handles
     *
     * @since   6.0.0
     */
    height: 15,
    /**
     * Array to define shapes of handles. 0-index for left, 1-index for
     * right.
     *
     * Additionally, the URL to a graphic can be given on this form:
     * `url(graphic.png)`. Note that for the image to be applied to
     * exported charts, its URL needs to be accessible by the export
     * server.
     *
     * Custom callbacks for symbol path generation can also be added to
     * `Highcharts.SVGRenderer.prototype.symbols`. The callback is then
     * used by its method name, as shown in the demo.
     *
     * @sample {highstock} stock/navigator/styled-handles/
     *         Styled handles
     *
     * @type    {Array<string>}
     * @default ["navigator-handle", "navigator-handle"]
     * @since   6.0.0
     */
    symbols: ['navigator-handle', 'navigator-handle'],
    /**
     * Allows to enable/disable handles.
     *
     * @since   6.0.0
     */
    enabled: true,
    /**
     * The width for the handle border and the stripes inside.
     *
     * @sample {highstock} stock/navigator/styled-handles/
     *         Styled handles
     *
     * @since     6.0.0
     * @apioption navigator.handles.lineWidth
     */
    lineWidth: 1,
    /**
     * The fill for the handle.
     *
     * @type    {Highcharts.ColorString|Highcharts.GradientColorObject|Highcharts.PatternObject}
     */
    backgroundColor: "#f2f2f2" /* Palette.neutralColor5 */,

    /**
     * The stroke for the handle border and the stripes inside.
     *
     * @type    {Highcharts.ColorString|Highcharts.GradientColorObject|Highcharts.PatternObject}
     */
    borderColor: "#999999" /* Palette.neutralColor40 */
  },
  /**
   * The color of the mask covering the areas of the navigator series
   * that are currently not visible in the main series. The default
   * color is bluish with an opacity of 0.3 to see the series below.
   *
   * @see In styled mode, the mask is styled with the
   *      `.highcharts-navigator-mask` and
   *      `.highcharts-navigator-mask-inside` classes.
   *
   * @sample {highstock} stock/navigator/maskfill/
   *         Blue, semi transparent mask
   *
   * @type    {Highcharts.ColorString|Highcharts.GradientColorObject|Highcharts.PatternObject}
   * @default rgba(102,133,194,0.3)
   */
  maskFill: color("#667aff" /* Palette.highlightColor60 */).setOpacity(0.3).get(),
  /**
   * The color of the line marking the currently zoomed area in the
   * navigator.
   *
   * @sample {highstock} stock/navigator/outline/
   *         2px blue outline
   *
   * @type    {Highcharts.ColorString|Highcharts.GradientColorObject|Highcharts.PatternObject}
   * @default #cccccc
   */
  outlineColor: "#999999" /* Palette.neutralColor40 */,

  /**
   * The width of the line marking the currently zoomed area in the
   * navigator.
   *
   * @see In styled mode, the outline stroke width is set with the
   *      `.highcharts-navigator-outline` class.
   *
   * @sample {highstock} stock/navigator/outline/
   *         2px blue outline
   *
   * @type    {number}
   */
  outlineWidth: 1,
  /**
   * Options for the navigator series. Available options are the same
   * as any series, documented at [plotOptions](#plotOptions.series)
   * and [series](#series).
   *
   * Unless data is explicitly defined on navigator.series, the data
   * is borrowed from the first series in the chart.
   *
   * Default series options for the navigator series are:
   * ```js
   * series: {
   *     type: 'areaspline',
   *     fillOpacity: 0.05,
   *     dataGrouping: {
   *         smoothed: true
   *     },
   *     lineWidth: 1,
   *     marker: {
   *         enabled: false
   *     }
   * }
   * ```
   *
   * @see In styled mode, the navigator series is styled with the
   *      `.highcharts-navigator-series` class.
   *
   * @sample {highstock} stock/navigator/series-data/
   *         Using a separate data set for the navigator
   * @sample {highstock} stock/navigator/series/
   *         A green navigator series
   *
   * @type {*|Array<*>|Highcharts.SeriesOptionsType|Array<Highcharts.SeriesOptionsType>}
   */
  series: {
    /**
     * The type of the navigator series.
     *
     * Heads up:
     * In column-type navigator, zooming is limited to at least one
     * point with its `pointRange`.
     *
     * @sample {highstock} stock/navigator/column/
     *         Column type navigator
     *
     * @type    {string}
     * @default {highstock} `areaspline` if defined, otherwise `line`
     * @default {gantt} gantt
     */
    type: typeof seriesTypes.areaspline === 'undefined' ? 'line' : 'areaspline',
    /**
     * The fill opacity of the navigator series.
     */
    fillOpacity: 0.05,
    /**
     * The pixel line width of the navigator series.
     */
    lineWidth: 1,
    /**
     * @ignore-option
     */
    compare: null,
    /**
     * @ignore-option
     */
    sonification: {
      enabled: false
    },
    /**
     * Unless data is explicitly defined, the data is borrowed from the
     * first series in the chart.
     *
     * @type      {Array<number|Array<number|string|null>|object|null>}
     * @product   highstock
     * @apioption navigator.series.data
     */
    /**
     * Data grouping options for the navigator series.
     *
     * @extends plotOptions.series.dataGrouping
     */
    dataGrouping: {
      approximation: 'average',
      enabled: true,
      groupPixelWidth: 2,
      // Replace smoothed property by anchors, #12455.
      firstAnchor: 'firstPoint',
      anchor: 'middle',
      lastAnchor: 'lastPoint',
      // Day and week differs from plotOptions.series.dataGrouping
      units: [['millisecond', [1, 2, 5, 10, 20, 25, 50, 100, 200, 500]], ['second', [1, 2, 5, 10, 15, 30]], ['minute', [1, 2, 5, 10, 15, 30]], ['hour', [1, 2, 3, 4, 6, 8, 12]], ['day', [1, 2, 3, 4]], ['week', [1, 2, 3]], ['month', [1, 3, 6]], ['year', null]]
    },
    /**
     * Data label options for the navigator series. Data labels are
     * disabled by default on the navigator series.
     *
     * @extends plotOptions.series.dataLabels
     */
    dataLabels: {
      enabled: false,
      zIndex: 2 // #1839
    },
    id: 'highcharts-navigator-series',
    className: 'highcharts-navigator-series',
    /**
     * Sets the fill color of the navigator series.
     *
     * @type      {Highcharts.ColorString|Highcharts.GradientColorObject|Highcharts.PatternObject}
     * @apioption navigator.series.color
     */
    /**
     * Line color for the navigator series. Allows setting the color
     * while disallowing the default candlestick setting.
     *
     * @type {Highcharts.ColorString|null}
     */
    lineColor: null,
    // #4602
    marker: {
      enabled: false
    },
    /**
     * Since Highcharts Stock v8, default value is the same as default
     * `pointRange` defined for a specific type (e.g. `null` for
     * column type).
     *
     * In Highcharts Stock version < 8, defaults to 0.
     *
     * @extends plotOptions.series.pointRange
     * @type {number|null}
     * @apioption navigator.series.pointRange
     */
    /**
     * The threshold option. Setting it to 0 will make the default
     * navigator area series draw its area from the 0 value and up.
     *
     * @type {number|null}
     */
    threshold: null
  },
  /**
   * Enable or disable navigator sticking to right, while adding new
   * points. If `undefined`, the navigator sticks to the axis maximum only
   * if it was already at the maximum prior to adding points.
   *
   * @type      {boolean}
   * @default   undefined
   * @since 10.2.1
   * @sample {highstock} stock/navigator/sticktomax-false/
   * stickToMax set to false
   * @apioption navigator.stickToMax
   */
  /**
   * Options for the navigator X axis. Default series options for the
   * navigator xAxis are:
   * ```js
   * xAxis: {
   *     tickWidth: 0,
   *     lineWidth: 0,
   *     gridLineWidth: 1,
   *     tickPixelInterval: 200,
   *     labels: {
   *            align: 'left',
   *         style: {
   *             color: '#888'
   *         },
   *         x: 3,
   *         y: -4
   *     }
   * }
   * ```
   *
   * @extends   xAxis
   * @excluding linkedTo, maxZoom, minRange, opposite, range, scrollbar,
   *            showEmpty, maxRange
   */
  xAxis: {
    /**
     * Additional range on the right side of the xAxis. Works similar to
     * `xAxis.maxPadding`, but the value is set in terms of axis values,
     * percentage or pixels.
     *
     * If it's a number, it is interpreted as axis values, which in a
     * datetime axis equals milliseconds.
     *
     * If it's a percentage string, is interpreted as percentages of the
     * axis length. An overscroll of 50% will make a 100px axis 50px longer.
     *
     * If it's a pixel string, it is interpreted as a fixed pixel value, but
     * limited to 90% of the axis length.
     *
     * If it's undefined, the value is inherited from `xAxis.overscroll`.
     *
     * Can be set for both, main xAxis and navigator's xAxis.
     *
     * @type    {number | string | undefined}
     * @since   6.0.0
     * @apioption navigator.xAxis.overscroll
     */
    className: 'highcharts-navigator-xaxis',
    tickLength: 0,
    lineWidth: 0,
    gridLineColor: "#e6e6e6" /* Palette.neutralColor10 */,
    id: 'navigator-x-axis',
    gridLineWidth: 1,
    tickPixelInterval: 200,
    labels: {
      align: 'left',
      /**
       * @type {Highcharts.CSSObject}
       */
      style: {
        /** @ignore */
        color: "#000000" /* Palette.neutralColor100 */,

        /** @ignore */
        fontSize: '0.7em',
        /** @ignore */
        opacity: 0.6,
        /** @ignore */
        textOutline: '2px contrast'
      },
      x: 3,
      y: -4
    },
    crosshair: false
  },
  /**
   * Options for the navigator Y axis. Default series options for the
   * navigator yAxis are:
   * ```js
   * yAxis: {
   *     gridLineWidth: 0,
   *     startOnTick: false,
   *     endOnTick: false,
   *     minPadding: 0.1,
   *     maxPadding: 0.1,
   *     labels: {
   *         enabled: false
   *     },
   *     title: {
   *         text: null
   *     },
   *     tickWidth: 0
   * }
   * ```
   *
   * @extends   yAxis
   * @excluding height, linkedTo, maxZoom, minRange, ordinal, range,
   *            showEmpty, scrollbar, top, units, maxRange, minLength,
   *            maxLength, resize
   */
  yAxis: {
    className: 'highcharts-navigator-yaxis',
    gridLineWidth: 0,
    startOnTick: false,
    endOnTick: false,
    minPadding: 0.1,
    id: 'navigator-y-axis',
    maxPadding: 0.1,
    labels: {
      enabled: false
    },
    crosshair: false,
    title: {
      text: null
    },
    tickLength: 0,
    tickWidth: 0
  }
};
/* *
 *
 *  Default Export
 *
 * */
export default NavigatorDefaults;
/* *
 *
 *  API Options
 *
 * */
/**
 * Maximum range which can be set using the navigator's handles.
 * Opposite of [xAxis.minRange](#xAxis.minRange).
 *
 * @sample {highstock} stock/navigator/maxrange/
 *         Defined max and min range
 *
 * @type      {number}
 * @since     6.0.0
 * @product   highstock gantt
 * @apioption xAxis.maxRange
 */
''; // Keeps doclets above in JS file