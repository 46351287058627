/* *
 *
 *  (c) 2010-2024 Torstein Honsi
 *
 *  License: www.highcharts.com/license
 *
 *  !!!!!!! SOURCE GETS TRANSPILED BY TYPESCRIPT. EDIT TS FILE ONLY. !!!!!!!
 *
 * */
'use strict';

import H from '../Globals.js';
const {
  isTouchDevice
} = H;
import U from '../Utilities.js';
const {
  addEvent,
  correctFloat,
  defined,
  isNumber,
  pick
} = U;
/* *
 *
 *  Functions
 *
 * */
/**
 * @private
 */
function onAxisInit() {
  const axis = this;
  if (!axis.navigatorAxis) {
    axis.navigatorAxis = new NavigatorAxisAdditions(axis);
  }
}
/**
 * For Stock charts, override selection zooming with some special features
 * because X axis zooming is already allowed by the Navigator and Range
 * selector.
 * @private
 */
function onAxisSetExtremes(e) {
  const axis = this,
    chart = axis.chart,
    chartOptions = chart.options,
    navigator = chartOptions.navigator,
    navigatorAxis = axis.navigatorAxis,
    pinchType = chart.zooming.pinchType,
    rangeSelector = chartOptions.rangeSelector,
    zoomType = chart.zooming.type;
  let zoomed;
  if (axis.isXAxis && (navigator?.enabled || rangeSelector?.enabled)) {
    // For y only zooming, ignore the X axis completely
    if (zoomType === 'y' && e.trigger === 'zoom') {
      zoomed = false;
      // For xy zooming, record the state of the zoom before zoom selection,
      // then when the reset button is pressed, revert to this state. This
      // should apply only if the chart is initialized with a range (#6612),
      // otherwise zoom all the way out.
    } else if ((e.trigger === 'zoom' && zoomType === 'xy' || isTouchDevice && pinchType === 'xy') && axis.options.range) {
      const previousZoom = navigatorAxis.previousZoom;
      // Minimum defined, zooming in
      if (defined(e.min)) {
        navigatorAxis.previousZoom = [axis.min, axis.max];
        // Minimum undefined, resetting zoom
      } else if (previousZoom) {
        e.min = previousZoom[0];
        e.max = previousZoom[1];
        navigatorAxis.previousZoom = void 0;
      }
    }
  }
  if (typeof zoomed !== 'undefined') {
    e.preventDefault();
  }
}
/* *
 *
 *  Class
 *
 * */
/**
 * @private
 * @class
 */
class NavigatorAxisAdditions {
  /* *
   *
   *  Static Functions
   *
   * */
  /**
   * @private
   */
  static compose(AxisClass) {
    if (!AxisClass.keepProps.includes('navigatorAxis')) {
      AxisClass.keepProps.push('navigatorAxis');
      addEvent(AxisClass, 'init', onAxisInit);
      addEvent(AxisClass, 'setExtremes', onAxisSetExtremes);
    }
  }
  /* *
   *
   *  Constructors
   *
   * */
  constructor(axis) {
    this.axis = axis;
  }
  /* *
   *
   *  Functions
   *
   * */
  /**
   * @private
   */
  destroy() {
    this.axis = void 0;
  }
  /**
   * Add logic to normalize the zoomed range in order to preserve the pressed
   * state of range selector buttons
   *
   * @private
   * @function Highcharts.Axis#toFixedRange
   */
  toFixedRange(pxMin, pxMax, fixedMin, fixedMax) {
    const axis = this.axis,
      halfPointRange = (axis.pointRange || 0) / 2;
    let newMin = pick(fixedMin, axis.translate(pxMin, true, !axis.horiz)),
      newMax = pick(fixedMax, axis.translate(pxMax, true, !axis.horiz));
    // Add/remove half point range to/from the extremes (#1172)
    if (!defined(fixedMin)) {
      newMin = correctFloat(newMin + halfPointRange);
    }
    if (!defined(fixedMax)) {
      newMax = correctFloat(newMax - halfPointRange);
    }
    if (!isNumber(newMin) || !isNumber(newMax)) {
      // #1195, #7411
      newMin = newMax = void 0;
    }
    return {
      min: newMin,
      max: newMax
    };
  }
}
/* *
 *
 *  Default Export
 *
 * */
export default NavigatorAxisAdditions;